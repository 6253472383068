import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'grommet';

const Container = styled(Box)`
  max-width: ${props => props.maxWidth};
`;

const FlexColumns = props => {
  const { children, maxWidth, flex, background, ...rest } = props;

  const renderChildren = () => {
    if (Array.isArray(children)) {
      return children.map((child, index) => (
        <Box key={index} flex={flex || 'grow'} align="center" justify="center">
          {child}
        </Box>
      ));
    } else {
      return children;
    }
  };

  return (
    <Container
      direction="row"
      justify="center"
      align="center"
      fill="horizontal"
      maxWidth={maxWidth}
      background={background}
      wrap
      {...rest}
    >
      {renderChildren()}
    </Container>
  );
};

FlexColumns.propTypes = {
  background: PropTypes.string,
  maxWidth: PropTypes.string,
  flex: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default FlexColumns;
