import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box, Heading } from 'grommet';

class CallOutCard extends PureComponent {
  render() {
    const { title, child, ...rest } = this.props;
    return (
      <Box align="center" pad="small" {...rest}>
        <Heading level={2} size="small" color="brand" textAlign="center">
          {title}
        </Heading>
        {child}
      </Box>
    );
  }
}

CallOutCard.propTypes = {
  title: PropTypes.string.isRequired,
  child: PropTypes.element.isRequired,
};

export default CallOutCard;
